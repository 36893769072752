<template>
  <card class="mx-auto my-20">
    <template #title>Forgot password</template>
    <template>
      <template v-if="!isChanged">
        <div class="mx-2 flex flex-col gap-2 justify-center">
          <form-input
            type="password"
            placeholder="Password"
            label="Password"
            v-model="password"
          />
          <form-input
            type="password"
            placeholder="Repeat Password"
            label="Repeat Password"
            v-model="password2"
          />
        </div>
        <div v-if="errors">
          <p
            v-for="(value, index) in errors"
            :key="index"
            class="text-red-500 text-xs px-2"
          >
            {{ value }}
          </p>
        </div>
        <t-button
          primary
          class="mx-16 mt-6"
          :loading="isLoading"
          @click="trySavePassword()"
        >
          Save password
        </t-button>
      </template>
      <template v-else>
        <div class="mx-1 flex flex-col gap-2 justify-center">
          <p class="pt-2 px-5 text-center text-xs">
            Your password was changed successfully,
            <br />
            please proceed to login with new credentials
          </p>
        </div>
        <t-button primary class="mx-16 mt-6" @click="$router.push('/signin')">
          LOGIN
        </t-button>
      </template>
    </template>
  </card>
</template>

<script>
import Card from "@/components/Card.vue";
import FormInput from "@/components/core/FormInput.vue";
import TButton from "@/components/core/Button.vue";

import { mapGetters } from "vuex";

export default {
  name: "SubmitPassword",
  components: {
    Card,
    FormInput,
    TButton,
  },
  data() {
    return {
      password: "",
      password2: "",
      isChanged: false,
    };
  },
  computed: {
    ...mapGetters({
      errors: "auth/errors",
      isLoading: "auth/isLoading",
    }),

    fieldsNotEmpty() {
      return (
        this.password &&
        this.password.length > 0 &&
        this.password2 &&
        this.password2.length > 0
      );
    },
    passwordsMatch() {
      return this.password === this.password2;
    },
  },
  methods: {
    async trySavePassword() {
      if (this.fieldsNotEmpty && this.passwordsMatch) {
        await this.$store.dispatch("auth/commitRestore", {
          email: this.$route.query.email,
          password: this.password,
          password2: this.password2,
          token: this.$route.query.token,
        });

        await this.commitChanged();
      } else {
        await this.$store.commit("auth/SET_ERRORS", {
          detail: "Fields should match",
        });
      }
    },

    commitChanged() {
      if (!this.errors) {
        this.isChanged = true;
      }
    },
  },
  beforeDestroy() {
    this.$store.commit("auth/SET_ERRORS", null);
  },
};
</script>
